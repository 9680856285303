import moment, { Moment } from 'moment-timezone'

export enum ArAdvanceStatus {
  Placed = 'placed',
  InReview = 'inReview',
  Approved = 'approved',
  NotApplied = 'notApplied',
  Completed = 'completed',
  Rejected = 'rejected',
}

export enum DisclosureType {
  DAF = 'DAF',
  UAF = 'UAF',
}

// values to be reviewed
export enum ArAdvanceEarlyPaymentDiscountDays {
  None = 'none',
  TD10 = 'TD10',
  EOM10 = 'EOM10',
}

// values to be reviewed
export enum AdvancePaymentDay {
  TD0 = 'TD0',
  TD1 = 'TD1',
  EOM1 = 'EOM1',
}

export enum PaymentDelayCode {
  TD30 = 'TD30',
  TD45 = 'TD45',
  TD60 = 'TD60',
  TD90 = 'TD90',
  TD120 = 'TD120',
  EOM10 = 'EOM10',
  EOM30 = 'EOM30',
  EOM45 = 'EOM45',
  EOM60 = 'EOM60',
}

enum PaymentDelayBase {
  TD = 'TD',
  EOM = 'EOM',
}

type IPaymentDelayCodeMetadata = {
  days: number
  base: PaymentDelayBase
}

export class PaymentDelayCodeParser {
  static getPaymentDelay(
    code: string,
    baseDate = moment(),
  ): { moment: Moment; seconds: number } {
    const metadata = this.parse(code)
    let paymentDate: Moment

    switch (metadata.base) {
      case PaymentDelayBase.TD:
        paymentDate = baseDate.clone().startOf('day').add(metadata.days, 'days')
        break
      case PaymentDelayBase.EOM:
        paymentDate = baseDate.clone().endOf('month').add(metadata.days, 'days')
        break
      default:
        throw new Error(`unsupported payment delay code ${code}`)
    }

    return {
      moment: paymentDate,
      seconds: paymentDate.diff(baseDate, 'seconds'),
    }
  }

  static parse(code: string): IPaymentDelayCodeMetadata {
    // PaymentDelayCode / AdvancePaymentDelayCode can be extended with more complex combination
    // for now use basic parsing for TDxx/ EOMxx, can be replaced later with pre-parsed objects (see file history)

    const regularCodeRegex = /^(?<base>TD|EOM)(?<days>\d+)$/

    const match = code.match(regularCodeRegex)

    if (!match) throw new Error(`unsupported payment delay code ${code}`)

    return {
      base: match.groups!.base as PaymentDelayBase,
      days: Number(match.groups!.days),
    }
  }

  static format(code: string): string {
    if (!code) return ''

    const metadata = this.parse(code)

    return metadata.base === PaymentDelayBase.TD
      ? `True ${metadata.days}`
      : metadata.base === PaymentDelayBase.EOM
      ? `EOM + ${metadata.days}`
      : ''
  }

  static getTestPaymentDelay(
    code: string,
    baseDate = moment(),
  ): { moment: Moment; seconds: number } {
    const metadata = this.parse(code)
    let paymentDate: Moment

    switch (metadata.base) {
      case PaymentDelayBase.TD:
        paymentDate = baseDate.clone().add(metadata.days * 15, 'minutes')
        break
      case PaymentDelayBase.EOM:
        paymentDate = baseDate.clone().endOf('hour').add(10, 'minutes')
        break
      default:
        throw new Error(`unsupported payment delay code ${code}`)
    }

    return {
      moment: paymentDate,
      seconds: paymentDate.diff(baseDate, 'seconds'),
    }
  }
}
