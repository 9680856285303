import { CustomerAccountType } from './customerAccountType'
import { invoiceStatus } from './invoiceStatus'

export interface IPayablesVendors {
  id: string
  supplierName: string
  supplierPhone: string
  supplierIsInvited: boolean
  customerAccountIds: string[]
  creditApplicationId: string
  arAdvanceIsEnabled: boolean
  inHouseCreditIsEnabled?: boolean
  totalDueInvoices: number
  totalDueSum: number
  totalPastDueInvoices: number
  totalPastDueSum: number
}

export interface IPayablesInvoices {
  _id: string
  invoice_number: string
  total_amount: number
  invoice_due_date: string
  type: string
  expiration_date: string
  invoice_date: string
  authorization_deadline: string
  payer_id: string
  lateFee: number
  payment_date: string | null
  createdAt: string
  invoiceStatus: string
  loanAppStatus: string
  quoteStatus: string
  customer_account_id: string
  paymentType: string | null
  customerType: CustomerAccountType
  supplierInvitationDetails: {
    paymentMethodId: string
    name: string
  }
  company: {
    _id: string
    phone: string
    name: string
    isInvited: boolean
  }
}

export enum PayablesVendorsSort {
  TOTAL_DUE = 'totalDue',
  TOTAL_PAST_DUE = 'totalPastDue',
  NAME = 'name',
  CREATED_AT = 'createdAt',
}

export enum PayablesInvoicesQuotesSort {
  AMOUNT = 'amount',
  PAYMENT_DATE = 'paymentDate',
  EXPIRATION_DATE = 'expirationDate',
  CREATED_AT = 'createdAt',
  DUE_DATE = 'dueDate',
}

export const PayablesVendorsSortColumn = {
  [PayablesVendorsSort.TOTAL_DUE]: 'totalDueSum',
  [PayablesVendorsSort.TOTAL_PAST_DUE]: 'totalPastDueSum',
  [PayablesVendorsSort.NAME]: 'supplierName',
  [PayablesVendorsSort.CREATED_AT]: 'latestInvoiceCreatedAt',
} as const

export const PayablesInvoicesQuotesSortColumn = {
  [PayablesInvoicesQuotesSort.AMOUNT]: 'total_amount',
  [PayablesInvoicesQuotesSort.PAYMENT_DATE]: 'payment_date',
  [PayablesInvoicesQuotesSort.EXPIRATION_DATE]: 'expiration_date',
  [PayablesInvoicesQuotesSort.CREATED_AT]: 'createdAt',
  [PayablesInvoicesQuotesSort.DUE_DATE]: 'invoice_due_date',
} as const

export type PayablesVendorsSortColumnType =
  keyof typeof PayablesVendorsSortColumn

export type PayablesInvoicesQuotesSortColumnType =
  keyof typeof PayablesInvoicesQuotesSortColumn

export const payableInvoiceStatuses = [
  invoiceStatus.pastDue,
  invoiceStatus.due,
  invoiceStatus.placed,
  invoiceStatus.paymentFailed,
  invoiceStatus.applicationCancelled,
  invoiceStatus.applicationRejected,
]
