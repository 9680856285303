import { fileDownloader } from '@linqpal/common-frontend/src/helpers'
import {
  CompanyStatus,
  editableModel,
  IUIBankAccount,
  LoanPaymentPlan,
  routes,
  routes2,
  UIBankAccountModel,
} from '@linqpal/models'
import {
  APPROVED_STATUSES,
  BankConnectionType,
  invitationTypes,
  PROCESSING_STATUSES,
  USER_ROLE,
} from '@linqpal/models/src/dictionaries'
import { event_names } from '@linqpal/models/src/dictionaries/eventsNames'
import { MAIN_ID } from '@linqpal/models/src/dictionaries/onboarding'
import makeInspectable from 'mobx-devtools-mst'
import { types } from 'mobx-state-tree'
import moment from 'moment'
import { Linking } from 'react-native'
import RootStore from '../../../store'
import LocalStorage from '../../../store/LocalStorage'
import { MessageSupport } from '../../../store/types/MessageSupport'
import { callCustomEvent } from '../../../utils/helpers/callCustomEvent'
import { isWeb } from '../../../utils/helpers/commonUtils'
import SignupStore from '../../Auth/stores/SignupStore'
import { dispatcher, pathFactory, paths } from '../../links'
import { v4 } from 'public-ip'
import { toJS } from 'mobx'
import { ApplicationType } from '@linqpal/models/src/dictionaries/applicationType'
import { CreditApplicationStatus } from '@linqpal/models/src/dictionaries/creditApplicationStatus'

export enum EditMode {
  NORMAL = 'normal',
  // edit just fields that are not filled yet, but stop on important questions.
  // Used when one application is submitted and user fills another one
  SKIP_FILLED = 'skipFilled',
  // edit just fields that are not filled yet.
  // Used when invited user fills missing fields from review page
  SKIP_FILLED_STRICT = 'skipFilledStrict',
}

const PERSONAL_INFO_STEPS = [
  'businessOwner.address',
  'businessOwner.birthdate',
  'businessOwner.ssn',
]

export const MIN_COOWNER_PERCENTAGE = 25

const props = {
  applicationType: types.enumeration(Object.values(ApplicationType)),
  loading: types.optional(types.boolean, false),
  buttonLoading: types.optional(types.boolean, false),
  showVerifyEmail: types.optional(types.boolean, false),
  invoiceDetails: types.optional(types.array(types.frozen()), []),
  successAlertData: types.optional(types.frozen(), {}),
  selectedPaymentPlan: types.maybeNull(
    types.compose(LoanPaymentPlan, types.model({ _id: types.string })),
  ),
  draftLastUpdatedDate: types.optional(types.string, ''),
  paymentPlans: types.array(
    types.compose(LoanPaymentPlan, types.model({ _id: types.string })),
  ),
  loadingPlans: types.optional(types.boolean, false),
  payAndSubmitApplication: types.optional(types.boolean, false),
  editMode: types.optional(
    types.enumeration<EditMode>(Object.values(EditMode)),
    EditMode.NORMAL,
  ),
  isPrimaryBankAccountChanged: types.optional(types.boolean, false),
  previousStep: types.optional(types.string, ''),
  agreementAlert: types.optional(types.boolean, false),
  currentCoOwnerIndex: -1,
  supplierId: '', // IHC supplier
}

function views(self) {
  return {
    get isPrequalifiedApplication() {
      const draft = RootStore.userStore?.document
      return self.isCreditApplication && !draft?.invoice_id
    },
    get isGetPaidApplication() {
      return self.applicationType === ApplicationType.Supplier
    },
    get isCreditApplication() {
      return self.applicationType === ApplicationType.Credit
    },
    get isInHouseCreditApplication() {
      return self.applicationType === ApplicationType.InHouseCredit
    },
    get isSubContractor() {
      const categorySelected = RootStore.userStore.document?.data
        ?.get('businessInfo')
        ?.get('category')
      return categorySelected === MAIN_ID.SUB_CONTRACTOR
    },
    get firstQuestion() {
      const document = RootStore.userStore.document

      const email = RootStore.userStore.user?.email
      const onboarding = RootStore.userStore.settings.get('onboardingType')

      const onboardingType = onboarding ? (onboarding as string[])[0] : ''

      const isSubContractor = onboardingType === MAIN_ID.SUB_CONTRACTOR
      const isSignUpFromInvoice = !!SignupStore.signupInvoiceId

      let firstQuestion = 'businessInfo.businessName'

      if (!email) {
        return 'businessInfo.email'
      } else if (document?.firstQuestion) {
        return document.firstQuestion
      } else if (!onboardingType || isSignUpFromInvoice) {
        firstQuestion = 'businessInfo.category'
      } else if (isSubContractor) {
        firstQuestion = 'businessInfo.trade'
      }

      document?.setFirstQuestion(firstQuestion)
      return firstQuestion
    },
    get shouldAcceptPersonalGuarantorAgreement() {
      const draft = RootStore.userStore?.document
      const revenue = draft?.get('finance', 'revenue')
      return Number(revenue) <= 10000000
    },
    get isOwner() {
      const draft = RootStore.userStore?.document
      return draft?.data?.get('businessOwner')?.get('isOwner') === 'Yes'
    },
    get isAuthorised() {
      const draft = RootStore.userStore?.document
      return draft?.data?.get('businessOwner')?.get('isAuthorised') === 'Yes'
    },
    get isInvitedOwner() {
      return RootStore.userStore.roles[0]?.role === 'Admin'
    },
    get hasSubmissionRights() {
      const userRole = RootStore.userStore.roles[0]?.role
      return this.isInHouseCreditApplication
        ? userRole && [USER_ROLE.ADMIN, USER_ROLE.OWNER].includes(userRole)
        : self.isAuthorised || self.isOwner
    },
    get authorisedInvitee() {
      const draft = RootStore.userStore?.document
      const auth = draft?.data?.get('businessOwner')?.get('authorisedDetails')
      return auth
    },
    get hasAuthorisedDetails() {
      return RootStore.userStore.document?.data
        ?.get('businessOwner')
        ?.get('authorisedDetails')
    },
    get hasOwnerPersonalInformation() {
      return PERSONAL_INFO_STEPS.every((step) =>
        self.isFilled(...step.split('.')),
      )
    },
    get ownerPercentage() {
      const draft = RootStore.userStore.document
      const share = draft?.get('businessOwner', 'ownershipPercentage')
      return share ? Number(share) : 0
    },
    get totalOwnershipPercentage() {
      const draft = RootStore.userStore.document
      const coOwnersPercentage = [1, 2, 3, 4]
        .map((index) => draft?.get('coOwnerInfo', `coOwner${index}`))
        .filter((coOwner) => coOwner)
        .reduce((sum, val) => sum + Number(val.percentOwned), 0)

      return this.ownerPercentage + coOwnersPercentage
    },
    availableOwnerPercentage() {
      return 100 - this.totalOwnershipPercentage + this.ownerPercentage
    },
    availableCoOwnerPercentage(index: number) {
      const draft = RootStore.userStore.document
      const coOwner = draft?.get('coOwnerInfo', `coOwner${index + 1}`)

      return 100 - this.totalOwnershipPercentage + (coOwner?.percentOwned || 0)
    },
    get canHaveCoOwners() {
      return 100 - this.totalOwnershipPercentage >= MIN_COOWNER_PERCENTAGE
    },
    get hasCoOwners() {
      return RootStore.userStore.document?.get('coOwnerInfo', 'coOwners')
    },
    get bankAccount() {
      return RootStore.userStore.document?.data?.get('bank')?.get('details')
    },
    get bankAccountConnectionType() {
      const selectedInApp =
        RootStore.screensStore.paymentMethodsStore.paymentMethods?.find(
          (p) => p._id === self.bankAccount?._id,
        )
      return selectedInApp?.connectionType
    },
    get shouldReconnectBank() {
      const onlineConnectedBanks = toJS(
        RootStore.screensStore.paymentMethodsStore.onlineConnectedBanks,
      )
      const manuallyAddedBanks = toJS(
        RootStore.screensStore.paymentMethodsStore.manuallyAddedBanks,
      )
      const connectedBankAccounts = [
        ...onlineConnectedBanks,
        ...manuallyAddedBanks,
      ]

      const primaryAccount = connectedBankAccounts.find(
        (b) => b.isPrimaryForCredit,
      )

      if (!primaryAccount) return true

      if (primaryAccount?.isManualEntry) return false

      if (primaryAccount?.connectionType !== BankConnectionType.PLAID)
        return true

      if (
        primaryAccount.plaid?.status === 'expired' ||
        primaryAccount.plaid?.status === 'disconnected'
      )
        return true

      return false
    },
    get isAnyApplicationStarted() {
      const groupsCount = RootStore.userStore.document?.data.size
      return !!groupsCount && groupsCount > 0
    },
    get isGetPaidSubmitted() {
      return [
        CompanyStatus.Applied,
        CompanyStatus.Approved,
        CompanyStatus.Rejected,
      ].includes(RootStore.userStore.company?.status || CompanyStatus.New)
    },
    get isCreditRequestSubmitted() {
      return [...APPROVED_STATUSES, ...PROCESSING_STATUSES].find(
        (status) => status === RootStore.userStore.document?.loanStatus,
      )
    },
    isOptionalStep(step?: string) {
      if (self.isInHouseCreditApplication) {
        if (step === 'finance.revenue') return true

        // bank details step is optional, but if exists, should be filled
        if (step === 'bank.details') {
          // TODO: VK: Review and remove check for bankAccount.id
          // something may create an empty bank account even if it was removed, use extra check for an empty id here
          if (!self.bankAccount || !self.bankAccount.id) return true
        }
      }

      return (
        step &&
        !self.isOwner &&
        !self.isAuthorised &&
        PERSONAL_INFO_STEPS.includes(step)
      )
    },
    bankValidator(value) {
      const fullBankData: IUIBankAccount | undefined =
        RootStore.screensStore.paymentMethodsStore.paymentMethods.find(
          (p) => p._id === value?._id,
        )
      if (fullBankData) {
        const { connectionType, plaid } = fullBankData
        const bank = UIBankAccountModel.create({
          ...value,
          connectionType,
          plaid,
        })
        return bank?.isFilled || (!self.isCreditApplication && bank.allFilled)
      } else {
        return !!value && UIBankAccountModel.create(value)?.isFilled
      }
    },
    isFilled(group: string, identifier: string) {
      if (group === 'bank') {
        return RootStore.userStore.document?.isFilled(
          group,
          identifier,
          self.bankValidator,
        )
      } else {
        return RootStore.userStore.document?.isFilled(group, identifier)
      }
    },
    canSubmit(steps: string[]) {
      return (
        this.hasSubmissionRights && !self.getIncompleteSections(steps).length
      )
    },
    verifyCanStartGetPaidApplication: () => {
      return RootStore.userStore
        .fetchDocument(ApplicationType.Supplier)
        .then(() => {
          if (self.isCreditRequestSubmitted) {
            const ownerEmail = self.authorisedInvitee?.email
            if (ownerEmail && ownerEmail !== RootStore.userStore?.user?.email) {
              return false
            }
          }
          return true
        })
    },
  }
}

function actions(self) {
  return {
    loadApplication: (
      applicationType: ApplicationType,
      supplierId?: string,
    ) => {
      const { userStore, screensStore } = RootStore

      self.applicationType = applicationType
      self.supplierId = supplierId ?? ''

      self.setLoading(true)
      self.setEditMode(EditMode.NORMAL)

      screensStore.paymentMethodsStore.fetchPaymentMethods()

      !!userStore.user &&
        userStore
          .fetchDocument(applicationType)
          .then((res) => {
            self.initApplicationDefaults()

            // no special requirements for IHC app steps
            if (self.isInHouseCreditApplication) return

            // for other types questions can be adjusted based on another application / user authorization status etc.
            self.adjustFirstQuestion()

            const question = self.isCreditApplication
              ? self.firstCreditRequestQuestion(res.updatedAt)
              : self.firstGetPaidQuestion()

            if (question) {
              self.setEditMode(EditMode.SKIP_FILLED)
              userStore.document?.setSection(question, false)
            }
          })
          .finally(() => {
            self.setButtonLoading(false) // enable Agree & Submit button (can be disabled after previous submission)
            self.setLoading(false)
          })
    },
    initApplicationDefaults: () => {
      const {
        userStore: { user, company, document },
      } = RootStore

      if (document && !document.data.size) {
        // init brand new document with signed-up user's defaults

        const businessName = {
          dba: '',
          legalName: company?.legalName || company?.name,
        }

        //company.name can be either legalName or dba... meaning if not equals it is dba...
        if (
          company?.name &&
          company?.legalName &&
          company.name !== company.legalName
        ) {
          businessName.dba = company.name
        }

        document.setData('businessName', businessName, '', 'businessInfo')

        const phoneNumber = user?.phone || company?.phone
        document.setData('businessPhone', phoneNumber, '', 'businessInfo')
      }
    },
    adjustFirstQuestion: () => {
      const { userStore } = RootStore
      const { document } = userStore

      if (document) {
        if (self.isInvitedOwner) {
          if (self.hasAuthorisedDetails) {
            document.setData(
              'isAuthorised',
              'Yes',
              '',
              'businessOwner',
              '',
              true,
            )
            document.setSection('review.review', false)
          }
        } else if (self.authorisedInvitee?.invited && self.isAuthorised) {
          self.setNotAuthData()
          document.setSection('review.review')
        }
      }
    },
    firstSkipped: (section) => {
      const questions = RootStore.userStore?.document?.data?.get(section)?.items
      const skipped = questions?.find((q) => !q.filled)

      // when user goes from Review to incomplete section he should be able to change option
      // so adjust step to previous. Otherwise user is unable to select different option
      switch (skipped?.identifier) {
        case 'authorisedDetails':
          return 'isAuthorised'
        default:
          return skipped?.identifier
      }
    },
    setEditMode: (value: EditMode) => {
      self.editMode = value
    },
    setLoading: (value) => {
      self.loading = value
    },
    setButtonLoading: (value) => {
      self.buttonLoading = value
    },
    setInvoiceDetails: (data) => {
      self.invoiceDetails = data
    },
    setSuccessAlertData: (data) => {
      self.successAlertData = data
    },
    setShowVerifyEmail: (value: boolean) => {
      self.showVerifyEmail = value
    },
    setDraftLastUpdatedDate: (value) => {
      self.draftLastUpdatedDate = value
    },
    setSelectedPaymentPlan: (value) => {
      self.selectedPaymentPlan = value
    },
    inviteButton: (sm) => {
      return sm ? 'Invite & Continue' : 'Send Invite'
    },
    setPayAndSubmitApplication: (val) => {
      self.payAndSubmitApplication = val
    },
    setPaymentPlans: (data) => {
      self.paymentPlans = data
    },
    setLoadingPlans: (val) => {
      self.loadingPlans = val
    },
    setIsPrimaryBankAccountChanged: (value) => {
      self.isPrimaryBankAccountChanged = value
    },
    setPreviousStep: (value?: string) => {
      self.previousStep = value
    },
    setCurrentCoOwnerIndex: (index: number) => {
      self.currentCoOwnerIndex = index
    },
    getPaymentPlans: (invoiceId, totalAmount) => {
      self.setPaymentPlans([])
      self.setLoadingPlans(true)
      if (invoiceId) {
        routes2.company
          .getPlans(invoiceId.toString(), totalAmount)
          .then((res: any) => self.setPaymentPlans(res.data))
          .finally(() => self.setLoadingPlans(false))
      }
    },
    selectPlanAndContinue: (loanApplicationId, onSuccess, invoices) => {
      self.setLoadingPlans(true)
      if (self.payAndSubmitApplication) {
        const invoiceIds = invoices.map((i) => i._id)
        routes.user
          .updatePlan(
            invoiceIds,
            self.selectedPaymentPlan?._id,
            loanApplicationId,
          )
          .then(onSuccess)
          .finally(() => {
            self.setLoadingPlans(false)
          })
      } else {
        LocalStorage.get('payment').then((res) => {
          const paymentData = res
          paymentData.invoiceDetails.paymentPlan = self.selectedPaymentPlan?._id
          LocalStorage.set('payment', paymentData).then(() => {
            onSuccess()
            self.setLoadingPlans(false)
          })
        })
      }
    },
    getSuccessAlertData: () => {
      const typeOfSubmission = {
        noInvoice: {
          title: 'CreditSubmitSuccessNoInvoice.title',
          message: 'CreditSubmitSuccessNoInvoice.message',
        },
        withInvoice: {
          title: 'CreditSubmitSuccessWithInvoice.title',
          message: 'CreditSubmitSuccessWithInvoice.message',
        },
      }
      const data = self.invoiceDetails
        ? typeOfSubmission.withInvoice
        : typeOfSubmission.noInvoice

      self.setSuccessAlertData(data)
    },
    goToQuestion: (initial, document) => {
      if (typeof initial === 'function') document.setSection(initial())
      else document.setSection(initial)
    },
    tryGoBackToReview: () => {
      if (self.previousStep?.includes('review')) {
        RootStore.userStore.document?.setCurrent(self.previousStep)
        self.previousStep = ''
      }
    },
    getInvoiceData: (
      id: string | string[],
      setPaymentPlanModalVisibility?: (arg: boolean) => void,
    ) => {
      routes.invoices
        .show(id)
        .then((resp) => {
          if (resp.invoices) {
            self.setInvoiceDetails(resp.invoices)
            setPaymentPlanModalVisibility && setPaymentPlanModalVisibility(true) //- Show plans page
          }
        })
        .catch((err) => {
          console.log(err.message)
        })
        .finally(() => self.setButtonLoading(false))
    },
    updateUserEmail: () => {
      const email = RootStore.userStore.document?.data
        ?.get('businessInfo')
        ?.get('email')
      routes2.user.updateInfo({ email })
    },
    updateCompanyCategory: () => {
      const category = RootStore.userStore.document?.data
        ?.get('businessInfo')
        ?.get('category')
      routes.company.settings({ onboardingType: [category] })
    },
    clearBankAccount: () => {
      RootStore.userStore.document?.setContent('bank', 'details', null, false)
    },
    updatePrimaryBankAccount: () => {
      if (self.isPrimaryBankAccountChanged && self.bankAccount) {
        const bankAccountAsPrimary = { ...self.bankAccount }
        if (self.isCreditApplication) {
          bankAccountAsPrimary.isPrimaryForCredit = true
        } else {
          bankAccountAsPrimary.isPrimary = true
        }

        routes.company.addBankAccount(bankAccountAsPrimary)
        self.setIsPrimaryBankAccountChanged(false)
      }
    },
    inviteUser: (invitationDataIdentifier: string) => {
      const document = RootStore.userStore.document
      const invitationData = document?.data
        .get('businessOwner')
        ?.items?.find((i) => i.identifier === invitationDataIdentifier)

      if (!invitationData)
        throw new Error(`Unable to find ${invitationDataIdentifier}`)

      const data = {
        ...invitationData.content,
        authorized: true,
        role: 'Admin',
        login: invitationData.content?.phone,
        type: self.isCreditApplication
          ? invitationTypes.CREDIT_INVITE
          : invitationTypes.GETPAID_INVITE,
      }
      const request = routes.invitation.inviteContractor(data)
      request.then(() => {
        document?.setData(
          invitationDataIdentifier,
          { ...invitationData.content, invited: true },
          '',
          'businessOwner',
        )
      })
      return request
    },
    saveOwnerData: async () => {
      const draft = RootStore.userStore.document
      const fields = ['firstName', 'lastName', 'email', 'phone', 'id']
      const values = fields.map(
        (field) => draft?.get('businessOwner', field)?.content,
      )

      if (values.some((value) => !value)) {
        await routes.contractor.saveOwnerData()
      }
    },
    submitCreditApplication: async (
      loanApplicationId = '',
      setInvoicePaymentMessage,
      navigation,
    ) => {
      try {
        const ipAddress = await v4()
        await routes.contractor.submitCreditApplication({
          loanApplicationId,
          ipAddress,
        })

        RootStore.userStore.fetchUser()
        RootStore.userStore.fetchDocument(ApplicationType.Credit)

        if (self.invoiceDetails?.supplierInvitationDetails) {
          routes.invoices
            .inviteSupplierSchedulePay(
              self.invoiceDetails?.id,
              RootStore.userStore.user?.sub,
              'credit',
            )
            .then(() => {
              callCustomEvent(event_names.submit_credit_application)
              setInvoicePaymentMessage(true)
            })
        } else {
          self.getSuccessAlertData()
        }

        if (navigation.canGoBack()) navigation.goBack()
        else {
          const action = dispatcher(pathFactory('Console.Credit.CreditHome'))
          navigation.replace(action.name, {})
        }
      } catch (e: any) {
        console.log(e)
        self.setErrorMessage(e.message)
        self.setButtonLoading(false) // no need to unblock button after successful submission
      }
    },
    agreeAndSubmit: async (
      setPaymentPlanModalVisibility,
      setInvoicePaymentMessage,
      navigation,
    ) => {
      const { userStore } = RootStore

      self.setButtonLoading(true)

      try {
        const emailVerification = await routes.user.checkEmailIsVerified()

        if (!emailVerification.isVerified) {
          self.setShowVerifyEmail(true)
          self.setButtonLoading(false)
          return
        }

        if (self.isCreditApplication) {
          const isNewLocAgreementAvailable =
            await userStore.isNewLoCAgreementAvailable()

          if (isNewLocAgreementAvailable) {
            self.setAgreementAlert(true)
            return
          }
        }

        await self.submit(
          setPaymentPlanModalVisibility,
          setInvoicePaymentMessage,
          navigation,
        )
      } catch (e) {
        console.log(e)
        self.setButtonLoading(false) // no need to unblock button after successful submission
      }
    },

    submit: async (
      setPaymentPlanModalVisibility,
      setInvoicePaymentMessage,
      navigation,
    ) => {
      self.setButtonLoading(true)

      const { userStore } = RootStore
      const draft = userStore.document

      if (!draft) return

      if (self.isInHouseCreditApplication) {
        await routes.contractor
          .submitInHouseCreditApplication(draft._id, self.supplierId)
          .then(() => {
            userStore.fetchUser().then(() => {
              userStore.updateInHouseCreditApplicationStatus(
                userStore?.company?.id ?? '',
                self.supplierId,
                CreditApplicationStatus.Processing,
              )
            })
            self.setSuccessAlertData({
              title: 'InHouseCreditSubmittedAlert.title',
              message: 'InHouseCreditSubmittedAlert.message',
            })

            navigation.replace(paths.Console._self)
          })
          .catch(() => self.setButtonLoading(false)) // no need to unblock button after successful submission
      } else if (self.isCreditApplication) {
        const invoiceId = draft?.invoice_id
        if (invoiceId) {
          self.saveOwnerData()
          self.getInvoiceData(invoiceId, setPaymentPlanModalVisibility)
        } else {
          self
            .submitCreditApplication(
              draft?.loanApplicationId,
              setInvoicePaymentMessage,
              navigation,
            )
            .catch(() => self.setButtonLoading(false)) // no need to unblock button after successful submission
        }
      } else {
        routes2.user
          .apply()
          .then(() => {
            userStore.fetchUser()
          })
          .then(() => {
            self.setSuccessAlertData({
              title: 'GetPaidSubmittedSuccessAlert.title',
              message: 'GetPaidSubmittedSuccessAlert.message',
            })

            navigation.replace(paths.Console._self)
          })
          .catch(() => self.setButtonLoading(false)) // no need to unblock button after successful submission
      }
    },

    handleDocumentUpdate: (
      newValue: any,
      itemName: string,
      groupName: string,
      filled: boolean,
      groupTitle: string,
    ) => {
      let overriddenValue = newValue
      if (groupName === 'businessOwner') {
        switch (itemName) {
          case 'ownerDetails':
          case 'authorisedDetails':
            overriddenValue = self.handleInvitationEmailUpdate(
              itemName,
              newValue,
            )
            break
          case 'isOwner':
            self.cleanupOnOwnerUpdate(newValue)
            break
          case 'isAuthorised':
            self.cleanupOnAuthorizedUpdate(newValue)
            break
        }
      }

      RootStore.userStore.document?.setData(
        itemName,
        newValue || overriddenValue,
        '',
        groupName,
        groupTitle,
        filled,
      )
    },
    cleanupOnOwnerUpdate: (newAnswer: any) => {
      const document = RootStore.userStore.document

      if (!self.isOwner && newAnswer === 'Yes') {
        document?.clearItems([
          'businessOwner.isAuthorised',
          'businessOwner.authorisedDetails',
        ])
      } else if (self.isOwner && newAnswer === 'No') {
        document?.clearItems(['businessOwner.ownershipPercentage'])
      }
    },
    cleanupOnAuthorizedUpdate: (newAnswer: any) => {
      const document = RootStore.userStore.document

      if (!self.isAuthorised && newAnswer === 'Yes') {
        document?.clearItems(['businessOwner.authorisedDetails'])
      }
    },
    setNotAuthData: () => {
      const document = RootStore.userStore.document
      document?.setData('isAuthorised', 'No', '', 'businessOwner')
    },
    handleInvitationEmailUpdate: (identifier, newData) => {
      const document = RootStore.userStore?.document
      if (self.authorisedInvitee?.invited) {
        if (
          newData?.email !==
          document?.data?.get('businessOwner')?.get(identifier)?.email
        ) {
          return { ...newData, invited: false }
        }
      }
      return null
    },
    firstGetPaidQuestion: () => {
      return self.isCreditRequestSubmitted ? 'bank.details' : ''
    },
    firstCreditRequestQuestion: (lastUpdateDate) => {
      const { document } = RootStore.userStore
      // const getPaidSubmittedDate =
      //   RootStore.userStore.user?.settings?.conversion
      //     ?.supplierApplicationSubmission

      self.setDraftLastUpdatedDate(lastUpdateDate)

      if (document?.loanStatus === 'new') {
        if (
          self.isGetPaidSubmitted ||
          document?.data?.get('finance')?.allFilled
        ) {
          if (moment(self.draftLastUpdatedDate) < moment().subtract(3, 'day')) {
            return 'finance.revenue'
          } else if (
            self.isCreditApplication &&
            !self.isFilled('finance', 'debt')
          ) {
            return 'finance.debt'
          } else if (
            self.isPrequalifiedApplication &&
            !self.isFilled('finance', 'howMuchCredit')
          ) {
            return 'finance.howMuchCredit'
          }
        }
      }
      return ''
    },
    allStepsFilled: (steps: string[]) => {
      // strict check ignoring optional fields
      return steps.every((step) => self.isFilled(...step.split('.')))
    },
    getIncompleteSections: (steps: string[]) => {
      const incompleteSections = new Set<string>()
      steps.forEach((step) => {
        const [group, identifier] = step.split('.')
        if (!self.isOptionalStep(step) && !self.isFilled(group, identifier)) {
          incompleteSections.add(group)
        }
      })
      return [...incompleteSections]
    },
    onClickLink(url) {
      if (isWeb) {
        window.open(url, '_blank')
      } else {
        Linking.openURL(url)
      }
    },
    previewPersonalGuarantorAgreement() {
      const draft = RootStore.userStore?.document
      routes.contractor
        .previewPersonalGuarantorAgreement(draft?._id ?? '')
        .then((res) => this.downloadFile(res.url, res.fileName))
    },
    previewMasterAgreement() {
      return routes.contractor
        .previewMasterAgreement()
        .then((res) => this.downloadFile(res.url, res.fileName))
    },
    setAgreementAlert(val: boolean) {
      self.agreementAlert = val
    },
    downloadFile(url: string, fileName: string) {
      if (url) {
        if (isWeb) {
          fileDownloader(url, {}, fileName)
        } else {
          Linking.openURL(url)
        }
      }
    },
  }
}

export const ApplicationStoreModel = types
  .compose(
    types.model(props).views(views).actions(actions),
    MessageSupport,
    editableModel(),
  )
  .named('ApplicationStore')
const store = ApplicationStoreModel.create({
  applicationType: ApplicationType.Credit,
})
makeInspectable(store)
export default store
