import { RouteBase } from '../types'
import {
  IGetLoanApplicationRequest,
  IViewAgreementRequest,
  ICreateAgreementRequest,
} from './types'
import { ILoanApplicationResponse } from '../user/types'

export class Migration extends RouteBase {
  domain = 'migration'

  getLoans(data: IGetLoanApplicationRequest) {
    return this.buildGetRoute<ILoanApplicationResponse>(this.getLoans, data)
  }

  viewAgreement(data: IViewAgreementRequest) {
    return this.buildGetRoute<any>(this.viewAgreement, data)
  }

  addAgreement(data: ICreateAgreementRequest) {
    return this.buildGetRoute<any>(this.addAgreement, data)
  }
}

export default new Migration()
